import { Box, ChakraProvider, extendTheme } from "@chakra-ui/react";
import React, { Suspense, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./layout/Layout";
import PaymentPage from "./pages/payment";
import CheckoutPage from "./pages/checkout";
import CompletedPage from "./pages/completed";
import { WEBVIEW_MESSAGES, postMessageToWebview } from "./lib/webview";
import { useTranslation } from "react-i18next";

const oldFetch = window.fetch;
window.fetch = async function fetch(...args) {
  const response = await oldFetch(...args);

  if (response.status === 401) {
    postMessageToWebview(WEBVIEW_MESSAGES.UNATHORIZED_RELOAD_WEBVIEW);
  }
  return response;
};

const Error = () => <Box>404</Box>;

const routes = [
  { path: "/checkout", Component: CheckoutPage },
  { path: "/payment", Component: PaymentPage },
  { path: "/completed", Component: CompletedPage },
  { path: "*", Component: Error },
];

const theme = extendTheme({
  colors: {
    green: {
      500: "#e2ff7e",
    },
    purple: {
      500: "#9747ff",
    },
  },
  semanticTokens: {
    colors: {
      primary: {
        default: "green.500",
      },
    },
  },
});

const App = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const lang = queryParams.get("lang");

    if (lang === "en") {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("de");
    }
  }, [i18n]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ChakraProvider theme={theme}>
        <Router>
          <Routes>
            {routes.map(({ path, Component }) => (
              <Route
                key={path}
                exact
                path={path}
                element={
                  <Layout>
                    <Component />
                  </Layout>
                }
              />
            ))}
          </Routes>
        </Router>
      </ChakraProvider>
    </Suspense>
  );
};

export default App;
