import { useEffect, useState } from "react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Spinner, Text, useToast } from "@chakra-ui/react";
import { createSearchParams, useLocation } from "react-router-dom";
import { captureOrder } from "../api/payment";
import {
  WEBVIEW_MESSAGES,
  postMessageToWebview,
  CHANNEL_NAME,
} from "../lib/webview";
import { PLATFORMS } from "./checkout";
import { Trans, useTranslation } from "react-i18next";

const CompletedPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  const { t } = useTranslation();
  const toast = useToast();
  const location = useLocation();

  useEffect(() => {
    if (!location.search) return;
    setIsLoading(true);

    const params = new URLSearchParams(location.search);
    const paymentType = params.get("payment_type");
    const token = params.get("token");
    const payment_intent_id = params.get("payment_intent_id");
    const platform = params.get("platform");

    if (platform === PLATFORMS.WEB) {
      return window.open(
        `${process.env.REACT_APP_WEB_REDIRECT_URL}?${createSearchParams({
          type: paymentType,
          id: payment_intent_id,
          token,
        }).toString()}`,
        "_self"
      );
    }

    captureOrder(token, { type: paymentType, id: payment_intent_id }).then(
      (orderData) => {
        if (!!orderData) {
          setIsSuccess(true);
          toast({
            status: "success",
            title: t("completed.toasts.success.title"),
            duration: 2000,
            isClosable: true,
          });
        } else {
          setIsSuccess(false);
          toast({
            status: "error",
            title: t("completed.toasts.error.title"),
            description: t("completed.toasts.error.message"),
            duration: 2000,
            isClosable: true,
          });
        }
        setIsLoading(false);
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setIsLoading, location]);

  const closeWindow = () => {
    if (window?.[CHANNEL_NAME]) {
      if (isSuccess) {
        postMessageToWebview(WEBVIEW_MESSAGES.CLOSE_CHECKOUT_SUCCESS);
      } else {
        postMessageToWebview(WEBVIEW_MESSAGES.CLOSE_CHECKOUT_FAILURE);
      }
    }
  };

  if (isLoading) {
    return (
      <Flex px={12} py={12} flexDirection="column" alignItems="center">
        <Spinner />
        <Text pt={6}>{t("completed.loading")}</Text>
      </Flex>
    );
  }

  return (
    <Box py={12} px={12} width="full">
      <Flex
        width="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        py={4}
      >
        <Text textAlign="center">
          {isSuccess ? (
            <>
              <CheckIcon color="green.400" /> {t("completed.successMessage")}
            </>
          ) : (
            <>
              <CloseIcon color="red.400" />{" "}
              <Trans
                t={t}
                i18nKey="completed.failMessage"
                components={{ lineBreak: <br /> }}
              />
            </>
          )}
        </Text>
        <Button onClick={closeWindow} mt={4}>
          {t("completed.buttons.close")}
        </Button>
      </Flex>
    </Box>
  );
};

export default CompletedPage;
