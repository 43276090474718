import { useEffect, useState } from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Button, Spinner, Text, useToast } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";
import { primaryColor, secondaryColor } from "../constants/colors";
import { PAYMENT_TYPES } from "../api/payment";
import { fetchUser } from "../api/cart";

const PurchaseOnAccount = () => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  const { t } = useTranslation();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    if (!location.state?.token) {
      return;
    }

    setLoading(true);

    fetchUser(location.state.token)
      .then((userData) => {
        setUser(userData);
        setLoading(false);
      })
      .catch((error) => {
        toast({
          status: "error",
          title: "Fehler",
          description: error,
        });
      });
  }, [location.state, setLoading, setUser, toast]);

  const handlePurchaseOnAccountClick = () => {
    setLoading(true);

    const path = `/completed?${createSearchParams({
      token: location.state.token,
      payment_type: PAYMENT_TYPES.PURCHASE_ON_ACCOUNT,
      payment_intent_id: "null",
      platform: location.state.platform,
      lang: searchParams.get("lang") || "de",
    })}`;

    navigate(path);
  };

  if (loading) {
    return <Spinner />;
  }

  if (!loading && !user) {
    return <Text>{t("payment.purchaseOnAccount.error")}</Text>;
  }

  return (
    <form onSubmit={handlePurchaseOnAccountClick}>
      <Text fontSize="sm" pb="20px">
        {t("payment.purchaseOnAccount.description")}
      </Text>

      <Text fontSize="md" pb="10px">
        <Trans
          t={t}
          i18nKey="payment.purchaseOnAccount.invoiceAddress"
          components={{ strong: <b /> }}
        />
      </Text>

      <Text pb="25px">
        {user?.companyName && (
          <>
            {user?.companyName}
            <br />
          </>
        )}
        {user?.firstName} {user?.lastName}
        <br />
        {user?.street}
        <br />
        {user?.zip} {user?.city}
      </Text>

      <Text fontSize="xs" pb="20px">
        {t("payment.purchaseOnAccount.invoiceAddressHint")}
      </Text>

      <Button
        width="full"
        type="submit"
        backgroundColor={primaryColor}
        color={secondaryColor}
        border={`${primaryColor} 1px solid`}
        _hover={{
          backgroundColor: "transparent",
          color: secondaryColor,
        }}
        disabled={loading}
        isLoading={loading}
      >
        {t("payment.purchaseOnAccount.buttons.purchase")}
      </Button>
    </form>
  );
};

export default PurchaseOnAccount;
